<script>
  import { mapGetters } from 'vuex';
  import AppBar from '@/components/general/app-bar/AppBar.vue';
  export default {
    components: {
      AppBar,
    },

    data() {
      return {
        currentStep: 1,
        steps: [
          {
            id: 1,
            image: require('@/assets/tutorial/Shelter_PencilBandit.png'),
            title: 'GETTING TO KNOW YOU',
            description: [
              `As we learn more about your preferences, we’ll suggest content that matches your interests and reflects your wellness goals.`,
            ],
          },
          {
            id: 2,
            image: require('@/assets/tutorial/Walk_PencilBandit.png'),
            title: 'EXPLORE WITH EASE',
            description: [
              `Click on Categories at any time to find activities that suit your immediate needs or mood.`,
              `You can filter by theme, type of content (i.e. audio or video), and length of exercise.`,
            ],
          },
          {
            id: 3,
            image: require('@/assets/tutorial/save-your-favs.png'),
            title: 'SAVE YOUR FAVES',
            description: [
              `If you like an exercise, hit the favourites button to save that in the Favourites tab. Navigate to your Profile to set goals and to check in on your progress.`,
            ],
          },
        ],
      };
    },
    computed: {
      ...mapGetters('OrganizationsModule', {
        organizationLogo: 'getOrganizationLogoUrl',
      }),
    },
    methods: {
      nextStep() {
        if (this.currentStep < this.steps.length) {
          this.currentStep++;
        } else {
          // if last step, take to explore page
          this.$router.push({ name: 'Explore' });
        }
      },
    },
  };
</script>
